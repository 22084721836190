// import './App.css';
import { Route, Routes } from "react-router-dom";
// import Home from "./pages/Home";
import { lazy, Suspense, useEffect, useState } from "react";
import loadingg from './images/loadinggif.gif';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fox from './images/fox.png'



// import Collegedetails from "./pages/Collegedetails";
// import About from "./pages/About";
// import Contactus from "./pages/Contactus";
// import Searchresult from "./pages/Searchresult";
// import Navbar from "./components/Navbar";
// import Studentlogin from "./pages/Studentlogin";
// import Studentsignup from "./pages/Studentsignup";
// import Termsandconditions from "./pages/Termsandconditions";
// import Forgetpass from "./pages/Forgetpass";
// import Sendotp from "./pages/Sendotp";
// import Createnewpass from "./pages/Createnewpass";

const  Home = lazy(()=> import( "./pages/Home"));
const  Collegedetails = lazy(()=> import( "./pages/Collegedetails"));
const  About = lazy(()=> import("./pages/About"));
const  Contactus= lazy(()=> import( "./pages/Contactus"));
const  Searchresult= lazy(()=> import( "./pages/Searchresult"));
const  Navbar= lazy(()=> import( "./components/Navbar"));
const  Studentlogin= lazy(()=> import( "./pages/Studentlogin"));
const  Studentsignup = lazy(()=> import("./pages/Studentsignup"));
const  Termsandconditions= lazy(()=> import( "./pages/Termsandconditions"));
const  Forgetpass= lazy(()=> import( "./pages/Forgetpass"));
const  Sendotp= lazy(()=> import( "./pages/Sendotp"));
const  Createnewpass= lazy(()=> import( "./pages/Createnewpass"));




function App() {

  const [isToastActive, setIsToastActive] = useState(false);


  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [wasOffline, setWasOffline] = useState(false);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);

      if (wasOffline) {
        // notifySuccess();
        toast.success('You are online')
        console.log("fmgbjhgcsjvj j ugsuc")
     
      }
    };

    const handleOffline = () => {
      setIsOnline(false);
      setWasOffline(true);


      toast.error('No Internet Connection')
      console.log("fmgbj")
    
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [wasOffline]);

  const showToast = (message) => {
      if (isToastActive) {
          toast.dismiss(); // Dismiss the currently active toast
      }

      toast(message, {
          onOpen: () => setIsToastActive(true), // Set the toast as active
          onClose: () => setIsToastActive(false), // Reset when toast is closed
          autoClose: 3000, // Duration in milliseconds
      });
  };



  return (
    <div className="App">
      <Navbar />
        <Suspense
          fallback={
            <>
            <img className="loading" src={loadingg} alt="loading....." style={{ marginTop: "20%", marginLeft: "40%" }}/>
          </>
                        }
        >
     {isOnline? <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Searchresult" element={<Searchresult />} />
          <Route path="/Collegedetails/:id" element={<Collegedetails />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/studentlogin" element={<Studentlogin />} />
          <Route path="/Studentsignup" element={<Studentsignup />} />
          <Route path="/termsandconditions" element={<Termsandconditions />} />
          <Route path="/Forgetpass" element={<Forgetpass />} />
          <Route path="/Sendotp" element={<Sendotp />} />
          <Route path="/Createnewpass" element={<Createnewpass />} />
      </Routes>:
      <div className="offline" style={{display:'flex' , justifyContent:'center' ,alignItems:'center',flexDirection:'column', marginBlock:'22vh'}}>
   
    <img className="confused" src={fox} alt="confused" style={{height:'400px'}}/>
    <h1>You Are Offline</h1>
      </div>}
        </Suspense>
    </div>
  );
}

export default App;
